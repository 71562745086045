import { schema } from 'normalizr';

export const PROJECTS_KEY = 'projects';
export const MODELS_KEY = 'models';
export const USERS_KEY = 'users';

const user = new schema.Entity(USERS_KEY);
const model = new schema.Entity(MODELS_KEY);
const project = new schema.Entity(PROJECTS_KEY, {
	// owner: user,
	model
});

export default {
	user,
	model,
	project,
}