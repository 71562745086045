import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { print } from 'graphql/language/printer'
export * from './queries';

const httpLink = createHttpLink({
	uri: process.env.REACT_APP_VISTA_API_BASE_URL + '/graphql',
});

const consoleLink = new ApolloLink((operation, forward) => {
  console.log(`starting request for operation ${operation.operationName}`);
  // let query = print(operation.query);
  let doc = operation.query;
  let query = doc.loc
    && doc.loc.source.body
    && doc.loc.source.body.replace(/\n/g,'').replace(/\s{2,}/g, ' ');
  let variables = operation.variables;
  console.log('query', query);
  console.log('variables', variables);
  return forward(operation).map((response) => {
    console.log('response', response);    
    console.log(`ending request for operation ${operation.operationName}`);
    return response;
  })
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authLink = setContext(async (request, { headers }) => {
  console.log('new request', request);
  const token = localStorage.getItem('token');
  const context = Object.assign({ headers: { ...headers }}, 
    token
    ? { headers: { authorization: token }} 
    : {}
  );
  // console.log(context);
  // await Promise.resolve().delay(300);
  return context;
});

const link = ApolloLink.from([authLink, errorLink, consoleLink, httpLink]);
const cache = new InMemoryCache();

const client = new ApolloClient({
	link,
	cache,
});

export default client;