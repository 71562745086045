import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { 
  Button,
  notification,
  List,
  Layout,
  Spin,
  Card,
  PageHeader,
  Avatar,
  Divider,
  Result,
  Form,
  Input,
  Icon,
  Typography
} from 'antd';

import './NewProject.css';

import { connect } from 'react-redux';
import actionsCreator from 'redux/actions';

import client, { 
  VistaClientError,
  AuthenticationError,
} from 'facils/vistaClient';

const { Header, Content, Footer } = Layout;
const { Text, Paragraph } = Typography;

const styles = {
  hover: {
    // border-color: rgba(0,0,0,0.09);
    // -webkit-box-shadow: 0 2px 8px rgba(0,0,0,0.09);
    // box-shadow: 0 2px 8px rgba(0,0,0,0.09);
    borderColor: 'rgba(0,0,0,0.06)',
    boxShadow: '0 2px 8px rgba(0,0,0,0.09)'
  }
}

const mapStateToProps = (state) => ({
  token: state.app.token,
  user: state.app.user,  
  projects: state.projects,
});

class NewProject extends Component {

  state = {
    projectName: '',
    creatingProject: false,
    project: null
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log(this.props);
  }

  handleProjectNameChange = (e) => {
    this.setState({ projectName: e.target.value });
  }  

  handleSubmitNewProject = (e) => {
    e.preventDefault();

    const projectName = this.state.projectName.trim();

    if (!projectName) {
      this.notify('Warning', 'Project Name is required', notification.warning);
    } else {
      this.createProject(projectName);
    }
  }

  createProject = async (name) => {
    const { dispatch } = this.props;

    this.setState({ creatingProject: true });

    try {
      let { project } = await client.createProject({ name });
      this.notify("Project Created", null, notification.success, 1.5)
      this.setState({ creatingProject: false, project });
    } catch(err) {
      this.setState({ creatingProject: false });
      this.notify("Error", err.message, notification.error);
    }    
  }  

  notify = (title, description, open, duration) => {
    const config = {
      message: title,
      description,
      duration
    };

    if (open) {
      open(config);
    } else {
      notification.info(config);
    }
  };  

  componentWillUnmount () {
  }  

  render() {
    const { history, match } = this.props;
    const { projectName, creatingProject, project } = this.state;

    const loading = creatingProject;

    return project
      ? (<Redirect to={`/projects/${project.id}`}/>)
      : (<Card
        bodyStyle={{ padding: '0px' }}
        style={{ 
          display: 'flex', 
          flexDirection: 'column', 
          width: '100%',
          maxWidth: 512,
          top: '10%',
          ...styles.hover}}>
        <PageHeader 
          title="Create A New Project"
          onBack={() => history.push('/projects')}/>
        <Divider style={{ margin: 0 }} />
        <div style={{ padding: '24px' }}>
          <Form
            layout='vertical'
            onSubmit={this.handleSubmitNewProject}>
            <Form.Item
              label={<Text style={{ fontSize: 16 }}>Name</Text>}
              validateStatus='success'
              help=''
              style={{paddingBottom: 0, marginBottom: 0}}>
              <Input size="large"  placeholder="Enter a name for this project" value={projectName} disabled={loading} onChange={this.handleProjectNameChange}/>
            </Form.Item>
            <Button htmlType="submit" type="primary" size="large" loading={loading} block style={{marginTop: 16}}>Create</Button>
          </Form>            
        </div>
      </Card>
    );
  }
}

export default connect(mapStateToProps)(NewProject);