import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import { connect } from 'react-redux';

import { Button } from 'antd';

// import './LoremIpsum.css';

const mapStateToProps = (state) => ({
});

class LoremIpsum extends Component {

  state = {
  }

  render() {

    return (
      <div>
        <Button type="primary">Button</Button>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          incididunt ut labore et dolore magna aliqua. Rhoncus dolor purus non enim praesent
          elementum facilisis leo vel. Risus at ultrices mi tempus imperdiet. Semper risus in
          hendrerit gravida rutrum quisque non tellus. Convallis convallis tellus id interdum
          velit laoreet id donec ultrices. Odio morbi quis commodo odio aenean sed adipiscing.
          Amet nisl suscipit adipiscing bibendum est ultricies integer quis. Cursus euismod quis
          viverra nibh cras. Metus vulputate eu scelerisque felis imperdiet proin fermentum leo.
          Mauris commodo quis imperdiet massa tincidunt. Cras tincidunt lobortis feugiat vivamus
          at augue. At augue eget arcu dictum varius duis at consectetur lorem. Velit sed
          ullamcorper morbi tincidunt. Lorem donec massa sapien faucibus et molestie ac.
        </p>
        <p>
          Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper eget nulla
          facilisi etiam dignissim diam. Pulvinar elementum integer enim neque volutpat ac
          tincidunt. Ornare suspendisse sed nisi lacus sed viverra tellus. Purus sit amet volutpat
          consequat mauris. Elementum eu facilisis sed odio morbi. Euismod lacinia at quis risus
          sed vulputate odio. Morbi tincidunt ornare massa eget egestas purus viverra accumsan in.
          In hendrerit gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
          et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis tristique
          sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis eleifend. Commodo
          viverra maecenas accumsan lacus vel facilisis. Nulla posuere sollicitudin aliquam
          ultrices sagittis orci a.
        </p>      
        <p>
          Aliquam facilisis erat quis porttitor ultricies. Etiam sit amet felis dictum, gravida sem 
          sed, dictum orci. Nulla risus dolor, placerat at felis tempor, ullamcorper condimentum nulla. 
          aesent ut ligula quis leo pulvinar ullamcorper lobortis vel odio. Mauris eleifend nisi velit, 
          a aliquam lectus fermentum euismod. Donec feugiat nulla nisl, nec vestibulum mauris mattis in. 
          Praesent porta quam massa, non fringilla sapien posuere eu. Fusce sapien leo, egestas vel 
          vestibulum sit amet, interdum vitae nisl.
        </p>
        <p>
          Proin vehicula id nibh iaculis congue. Sed eget sem ac nunc viverra luctus. Praesent commodo 
          ipsum nulla. Duis odio nulla, rhoncus in consequat ut, cursus ac nunc. Aliquam ex lacus, 
          tristique quis ipsum ac, ornare pretium quam. Nulla viverra nisi eget consectetur consectetur. 
          Nulla tincidunt, felis id fermentum aliquam, purus orci finibus lectus, sit amet molestie massa 
          massa sed sem. Vestibulum in augue ac ante elementum auctor quis vitae sapien. Cras sed leo 
          condimentum, dignissim velit sit amet, tincidunt arcu. Aenean dolor est, tempus vel gravida a, 
          fermentum et libero. Vestibulum sagittis massa ut metus sagittis, tempus convallis nibh volutpat.
        </p>        
      </div>
    );
  }
}

export default connect(mapStateToProps)(LoremIpsum);